import { Image } from 'antd'
import React from 'react'

export default function Home({ survey, emirli, baiji }) {
    return (
        <>
            <section id="about" className="hero d-flex align-items-center">

                <div className="container">
                    <div className="row   justify-content-center align-items-center">
                        <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
                            <h1 style={{ color: 'rgb(239, 64, 63)' }}>برنامج معراج</h1>
                            <h2 style={{ fontSize: 25 }}>برنامج ثقافي ترفيهي يرعى مختلف أفراد المجتمع وفئاته بتقديمه تجربة فريدة تكسبه ما يحتاجه من المقومات المعنوية لحياة طيبة. يهدف البرنامج  لتوفير تجربة ترفيهية عائلية أمنة ثقافياً</h2>
                            {survey === "true" && (emirli === "true" || baiji === "true") &&
                                <div><a href="#survey" className="btn-get-started scrollto survey-btn ps-5 pe-5">الأشتراك في رحلات معراج</a></div>
                            }
                        </div>
                        <div
                            className="col-lg-6 order-1 order-lg-2 d-flex align-items-center justify-content-center hero-img position-relative">
                            <img
                                src={require('../style/img/home/miraj_header.png')}
                                width={450}
                                className="img-fluid  d-xl-block d-xxl-block d-lg-none d-none"
                                alt=""
                            />
                            <img
                                src={require('../style/img/home/logo.png')}
                                width={250}
                                className="img-fluid  d-lg-block d-xl-none d-xxl-none d-block "
                                alt=""
                            />
                        </div>
                    </div>
                </div>

            </section>

            <main id="royatna">

                <section id="about" className="about image-move">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-9 col-lg-6 icon-boxes d-flex flex-column align-items-stretch justify-content-center py-5 px-lg-5">
                                <div className='title'>
                                    <div className='d-flex'>
                                        <p className='m-0 p-0' style={{ color: '#0c7676' }}>أرض</p>
                                        <p className='m-0 p-0 px-1 heartbeat' style={{ color: '#ef403f' }}>تنبض</p>
                                    </div>

                                    <p className='m-0' style={{ color: '#0c7676' }}>بالحكايا</p>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-6 d-flex justify-content-center  align-items-center position-relative">
                                <img
                                    src={require('../style/img/home/logo.png')}
                                    className=""
                                    style={{
                                        width: 300
                                    }}
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </section>
                <section id="qymna" className="counts">
                    <div className="container">

                        <div className="text-center title">
                            <h3 style={{ fontSize: 55, color: 'rgb(239, 64, 63)' }}>رؤيتنا</h3>
                            <h3>مجتمع يرى الحياة بمنظار الشهداء</h3>
                        </div>
                    </div>
                </section>
                {survey === "true" && (emirli === "true" || baiji === "true") &&
                    <section id="survey" className="services section-bg">
                        <div className="container">

                            <div className="section-title">
                                <h2> إستمارة الإشتراك في رحلات معراج الموسم الخامس</h2>
                                <p>معراج هو برنامج  ترفيهي لإحياء تراث الشهداء بطريقة مبتكرة و فريدة ، رحلة تكون فيها انت و عائلتك على ارض تحمل اسرار المعركة ، تسمع الاحداث و تعيش التجربة في جو تسامحي و ايماني يصح ان يكون انموذجاً للحياة.</p>
                            </div>

                            <div className="row justify-content-center  align-items-center">
                                {emirli === "true" &&
                                    <div className="col-lg-6 col-md-6">
                                        <div className="icon-box">
                                            <div className="icon"><i className="bi bi-card-text" style={{ color: '#ef403f' }}></i></div>
                                            <h4 className="title"><a href="/emirli"> أستمارة رحلة أمرلي</a></h4>
                                            <p className="description">الآن بإمكانكم حجز مقاعدكم والتسجيل لرحلة معراج للموسم الخامس لمدينة امرلي من خلال هذه الاستمارة</p>
                                        </div>
                                    </div>
                                }
                                {baiji === "true" &&
                                    <div className="col-lg-6 col-md-6">
                                        <div className="icon-box">
                                            <div className="icon"><i className="bi bi-card-text" style={{ color: '#0c7676' }}></i></div>
                                            <h4 className="title"><a href="/baiji">أستمارة رحلة بيجي</a></h4>
                                            <p className="description">الآن بإمكانكم حجز مقاعدكم والتسجيل لرحلة معراج للموسم الخامس لمدينة بيجي من خلال هذه الاستمارة</p>
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                    </section>
                }
                <section id="muhmaatna" className="testimonials">
                    <div className="container position-relative">

                        <div className="testimonials-slider swiper" data-aos="fade-up" data-aos-delay="100">
                            <div className="swiper-wrapper">

                                <div className="swiper-slide">
                                    <div className="testimonial-item">
                                        <img src={require('../style/img/home/logo_w.png')} className="testimonial-img" alt="" />
                                        <h3 style={{ fontSize: 55 }}>رسالتنا</h3>
                                        <p>
                                            <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                                            <span>تجربة فريدة للمجتمع، يعيش خلالها مواقف من حياة الشهداء بكل أحاسيسهم ومشاعرهم ليتوق للمكانة التي وصلوا لها فيعود الى الله. </span>
                                            <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-pagination"></div>
                        </div>

                    </div>
                </section>


                <section id="gallery" className="gallery">
                    <div className="container">

                        <div className="section-title">
                            <h2>هي حكايا…… تُرى قبل ان تُسمع</h2>
                        </div>

                        <div className="row no-gutters">

                            <div className="col-lg-3 col-md-4">
                                <div className="gallery-item">
                                    <div className="galleery-lightbox" data-gallery="gallery-item">
                                        <Image preview={{ mask: false }} src={require('../style/img/home/mr1.jpg')} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4">
                                <div className="gallery-item">
                                    <div className="galleery-lightbox" data-gallery="gallery-item">
                                        <Image preview={{ mask: false }} src={require('../style/img/home/mr2.jpg')} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4">
                                <div className="gallery-item">
                                    <div className="galleery-lightbox" data-gallery="gallery-item">
                                        <Image preview={{ mask: false }} src={require('../style/img/home/mr3.jpg')} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4">
                                <div className="gallery-item">
                                    <div className="galleery-lightbox" data-gallery="gallery-item">
                                        <Image preview={{ mask: false }} src={require('../style/img/home/mr4.jpg')} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4">
                                <div className="gallery-item">
                                    <div className="galleery-lightbox" data-gallery="gallery-item">
                                        <Image preview={{ mask: false }} src={require('../style/img/home/mr5.jpg')} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4">
                                <div className="gallery-item">
                                    <div className="galleery-lightbox" data-gallery="gallery-item">
                                        <Image preview={{ mask: false }} src={require('../style/img/home/mr6.jpg')} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4">
                                <div className="gallery-item">
                                    <div className="galleery-lightbox" data-gallery="gallery-item">
                                        <Image preview={{ mask: false }} src={require('../style/img/home/mr7.jpg')} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-4">
                                <div className="gallery-item">
                                    <div className="galleery-lightbox" data-gallery="gallery-item">
                                        <Image preview={{ mask: false }} src={require('../style/img/home/mr8.jpg')} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </section>
            </main>


        </>
    )
}
