import React, { useEffect, useState } from 'react';
import { Button, Form, Input, InputNumber, Result, Select, Steps, theme } from 'antd';
import axios from 'axios';
import { AddPop, ErrorPop, OKPop } from './PopForm';
export default function Reviews() {
    const [form] = Form.useForm()
    const { token } = theme.useToken();
    const [current, setCurrent] = useState(0);
    const [trips, setTrips] = useState([]);
    const [formData, setFormData] = useState({ type: 0 });
    const [post, setPost] = useState({
        progress: 0,
        count: 0,
    })
    // search values to select forms
    const [formPop, setFormPop] = useState({
        showOne: false,
        showTwo: false,
        showError: false,
        ok: false,
        errorMessage: '',
    })
    // func to change values in form
    const change = (name, value) => {
        setFormData((state) => {
            return { ...state, [name]: value }
        })
    }
    const changePost = (name, value) => {
        setPost((state) => {
            return { ...state, [name]: value }
        })
    }
    // func to change values in pop form
    const popForm = (name, value) => {
        setFormPop((state) => {
            return { ...state, [name]: value }
        })
    }

    const postData = async (event) => {
        event.preventDefault()
        const UploadProgress = (event) => {
            const { loaded, total } = event;
            let percent = Math.floor((loaded * 100) / total);
            console.log(`${loaded}kb of ${total}kb | ${percent}%`);
            changePost('progress', percent)
        }
        const option = {
            timeout: 5000000,
            onUploadProgress: UploadProgress
        }

        await axios.post('/reviews/api/v1/survey/', formData, option)
            .catch(error => {
                popForm('showTwo', false);
                popForm('showError', true);
                changePost('progress', 0);
                popForm('errorMessage', "رجاءا تاكد من الاتصال");
            }).then(res => {
                console.log(res.status)
                if (res.status === 201) {
                    popForm('showTwo', false);
                    popForm('ok', true);
                    setTimeout(() => {
                        window.location.reload()
                    }, 2000);
                }
            })
    }
    const next = () => {
        setCurrent(current + 1);
    };
    const prev = () => {
        setCurrent(current - 1);
    };
    const part = ['الرحلة الاولى', 'الرحلة الثانية', 'الرحلة الثالثة', 'الرحلة الرابعة', 'الرحلة الخامسة', 'الرحلة السادسة', 'الرحلة السابعة', 'الرحلة الثامنة']
    const status = ['جيد', "متوسط", "ضعيف"]
    const first = ['الرحلة الاولى', 'الرحلة الثالثة', 'الرحلة الرابعة', 'لم اشارك']
    const second = ['الرحلة الاولى', 'الرحلة الثانية', 'الرحلة الثالثة', 'الرحلة الرابعة', 'الرحلة الخامسة', 'الرحلة السادسة', 'الرحلة السابعة', 'لم اشارك']
    const Informations = () => {
        return (
            <div className='d-flex flex-column justify-content-start align-items-start'>
                <div className='information d-flex flex-column justify-content-start align-items-start'>
                    <div className='d-flex justify-content-start align-items-center w-100'>
                        <p className='step-title'> ملاحظات التقييم</p>
                    </div>
                    <p className='information-text'><p>*</p> من المشاركين الأوائل المؤسسين لهذا المشروع، نحن خدم الشهداء نعتذر منكم عن أي تقصير صدر منا ونرجوا ان يحسب علينا نحن، لا على المشروع.</p>
                    <p className='information-text'><p>*</p> نرجوا مساعدتنا في تقويم المشروع بالمشاركة الدقيقة والفاعلة في ملئ إستمارة التقييم الخاصة بالمشاركين في رحلات الموسم الخامس .</p>
                </div>

            </div>
        )
    }

    const Res = () => {
        return (
            <Result
                status="success"
                title="تم ملء الاستمارة بنجاح"
            />
        )
    }
    const steps = [
        {
            title: 'ملاحظات التقييم',
        },
        {
            title: 'البيانات الأساسية ',
        },
        {
            title: 'تقييم المحطات',
        },
        {
            title: 'التقييم العام',
        },
        {
            title: 'رفع التقييم',
        },
    ];
    useEffect(() => {
        async function getData() {

            await axios.get(`/setting/api/v1/settings/find/?key=reviews_trips`)
                .then(res => {
                    const data = res.data
                    if (data && data.value) {
                        const dataParse = JSON.parse(data.value)
                        setTrips(dataParse)
                    }
                })
        }
        getData()
    }, [])
    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
    }));
    const contentStyle = {
        color: token.colorTextTertiary,
        backgroundColor: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        border: `1px dashed ${token.colorBorder}`,
        marginTop: 16,
        zIndex: 3,
        minHeight: 300
    };

    const onPost = (values) => {
        if (values.has_children) {
            setFormData((state) => {
                return { ...state, ...values }
            })
        } else {
            setFormData((state) => {
                return { ...state, ...values, children_count: 0, children_ages: [], season: 5 }
            })
        }
        next()
    }
    return (
        <>
            <div className="container mb-5" style={{ minHeight: "80vh", overflow: 'auto', marginTop: 100 }} >
                <div className="row">
                    <div className="col-12 d-flex flex-column justify-content-center align-items-center" style={{ zIndex: 3 }}>
                        <img src={require('../style/img/home/logo.png')} alt="" className="img-fluid" width={175} />
                        <h3 className='text-center pt-2 pb-5'>أستمارة تقييم الرحلات</h3>
                    </div>
                    <div class="col-12 h-100">
                        <Form form={form} onFinish={(values) => onPost(values)}>
                            <Steps current={current} items={items} style={{ zIndex: 3 }} />
                            {current === 0 && <div style={{ ...contentStyle }}><Informations /></div>}
                            {current === 1 &&
                                <div style={{ ...contentStyle }}>
                                    <div className='d-flex justify-content-start align-items-center w-100'>
                                        <p className='step-title'>البيانات الأساسية</p>
                                    </div>
                                    <div class="container-fluid p-2 mt-1 mb-4 ">
                                        <div class="row justify-content-start align-items-start g-2">
                                            <div class="col-12">
                                                <label className='text-end optional'>اختر الرحلة :</label>
                                                <Form.Item
                                                    name='trips'
                                                    className='my-0'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل الزامي',
                                                        },
                                                    ]}
                                                    hasFeedback
                                                >
                                                    <Select
                                                        size="large"
                                                        className='col-12'
                                                        placeholder='اختر الرحلة'
                                                    >
                                                        {part.map((item, index) => {
                                                            if (!trips[index][index]) {
                                                                return null
                                                            }
                                                            return (
                                                                <option value={item}>{item}</option>
                                                            )
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <div class="col-lg-3 col-md-6 col-sm-12">
                                                <label className='text-end optional'>رقم الباص :</label>
                                                <Form.Item
                                                    name='bus'
                                                    className='my-0'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل الزامي',
                                                        },
                                                    ]}
                                                    hasFeedback
                                                >
                                                    <InputNumber className='col-12' type='number' min={0} max={10} size="large" placeholder='رقم الباص' name='age' />
                                                </Form.Item>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                <label className='text-end optional'>المجموعات الخاصة بالمشتركين : </label>
                                                <Form.Item
                                                    name='group_add'
                                                    className='my-0'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل الزامي',
                                                        },
                                                    ]}
                                                    hasFeedback
                                                    extra='هل تم اضافتك في المجموعات الخاصة بالمشتركين على تطبيق التلكرام للموسم ؟ الثالث'
                                                >
                                                    <Select
                                                        size="large"
                                                        className='col-12'
                                                        placeholder='المجموعات الخاصة بالمشتركين'
                                                    >
                                                        <option value={true}>نعم</option>
                                                        <option value={false}>كلا</option>
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <div class="col-lg-3 col-md-6 col-sm-12">
                                                <label className='text-end optional'> رحلات الموسم الاول : </label>
                                                <Form.Item
                                                    name='first_season'
                                                    className='my-0'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل الزامي',
                                                        },
                                                    ]}
                                                    hasFeedback
                                                    extra='هل شاركت في رحلات الموسم الاول من معراج ؟'
                                                >
                                                    <Select
                                                        size="large"
                                                        className='col-12'
                                                        placeholder='رحلات الموسم الاول'
                                                    >

                                                        {first.map(item => <option value={item}>{item}</option>)}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <div class="col-lg-4 col-md-6 col-sm-12">
                                                <label className='text-end optional'> رحلات الموسم الثاني : </label>
                                                <Form.Item
                                                    name='second_season'
                                                    className='my-0'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل الزامي',
                                                        },
                                                    ]}
                                                    hasFeedback
                                                    extra='هل شاركت في رحلات الموسم الثاني من معراج ؟'
                                                >
                                                    <Select
                                                        size="large"
                                                        className='col-12'
                                                        placeholder='رحلات الموسم الثاني'
                                                    >
                                                        {second.map(item => <option value={item}>{item}</option>)}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <div class="col-lg-4 col-md-12 col-sm-12">
                                                <label className='text-end optional'>الجنس :</label>
                                                <Form.Item
                                                    name='sex'
                                                    className='my-0'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل الزامي',
                                                        },
                                                    ]}
                                                    hasFeedback
                                                >
                                                    <Select
                                                        size="large"
                                                        className='col-12'
                                                        placeholder='الجنس'
                                                    // defaultValue={formData.sex}
                                                    // onChange={(e) => change('sex', e)}
                                                    >
                                                        <option value='ذكر'>ذكر</option>
                                                        <option value='انثى'>انثى</option>

                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <div class="col-lg-4 col-md-6 col-sm-12">
                                                <label className='text-end optional'>العمر :</label>
                                                <Form.Item
                                                    name='age'
                                                    className='my-0'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل الزامي',
                                                        },
                                                    ]}
                                                    hasFeedback
                                                >
                                                    <InputNumber className='col-12' type='number' min={15} max={100} size="large" placeholder='العمر' name='age' />
                                                </Form.Item>
                                            </div>
                                            <div class="col-lg-4 col-md-6 col-sm-12">
                                                <label className='text-end optional'>طالب جامعي : </label>
                                                <Form.Item
                                                    name='college_student'
                                                    className='my-0'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل الزامي',
                                                        },
                                                    ]}
                                                    hasFeedback
                                                >
                                                    <Select
                                                        size="large"
                                                        className='col-12'
                                                        placeholder='طالب جامعي'
                                                    >
                                                        <option value={true}>نعم</option>
                                                        <option value={false}>كلا</option>

                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <div class="col-lg-4 col-md-6 col-sm-12">
                                                <label className='text-end optional'>عضوية موكب أو هيئة حسينية : </label>
                                                <Form.Item
                                                    name='parade_member'
                                                    className='my-0'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل الزامي',
                                                        },
                                                    ]}
                                                    hasFeedback
                                                    extra='هل انت عضو في موكب أو هيئة حسينية ؟'
                                                >
                                                    <Select
                                                        size="large"
                                                        className='col-12'
                                                        placeholder='عضوية موكب أو هيئة حسينية'
                                                    >
                                                        <option value={true}>نعم</option>
                                                        <option value={false}>كلا</option>

                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <div class="col-lg-4 col-md-6 col-sm-12">
                                                <label className='text-end optional'>الاستمارة الألكترونية : </label>
                                                <Form.Item
                                                    name='form_fill'
                                                    className='my-0'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل الزامي',
                                                        },
                                                    ]}
                                                    hasFeedback
                                                    extra='هل ملأت الاستمارة الألكترونية الخاصة بالتسجيل ؟'
                                                >
                                                    <Select
                                                        size="large"
                                                        className='col-12'
                                                        placeholder='الاستمارة الألكترونية'
                                                    >
                                                        <option value={true}>نعم</option>
                                                        <option value={false}>كلا</option>

                                                    </Select>
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {current === 3 &&
                                <div style={{ ...contentStyle }}>
                                    <div className='d-flex justify-content-start align-items-center w-100'>
                                        <p className='step-title'>التقييم العام</p>
                                    </div>
                                    <div class="container-fluid p-2 mt-1 mb-4 ">
                                        <div class="row justify-content-start align-items-start g-2">
                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                <label className='text-end optional'>سلاسة التسجيل في الرحلة :</label>
                                                <Form.Item
                                                    name='registration_form'
                                                    className='my-0'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل الزامي',
                                                        },
                                                    ]}
                                                    hasFeedback
                                                >
                                                    <Select
                                                        size="large"
                                                        className='col-12'
                                                        placeholder='سلاسة التسجيل في الرحلة'
                                                    >
                                                        {status.map(item => <option value={item}>{item}</option>)}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                <label className='text-end optional'>مواعيد الاستعداد والالتحاق  بسيارات النقل :</label>
                                                <Form.Item
                                                    name='appointments'
                                                    className='my-0'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل الزامي',
                                                        },
                                                    ]}
                                                    hasFeedback
                                                >
                                                    <Select
                                                        size="large"
                                                        className='col-12'
                                                        placeholder='مواعيد الاستعداد والالتحاق  بسيارات النقل'
                                                    >
                                                        {status.map(item => <option value={item}>{item}</option>)}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <div class="col-lg-4 col-md-6 col-sm-12">
                                                <label className='text-end optional'>الراحة في سيارات النقل :</label>
                                                <Form.Item
                                                    name='comfort'
                                                    className='my-0'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل الزامي',
                                                        },
                                                    ]}
                                                    hasFeedback
                                                >
                                                    <Select
                                                        size="large"
                                                        className='col-12'
                                                        placeholder='الراحة في سيارات النقل'
                                                    >
                                                        {status.map(item => <option value={item}>{item}</option>)}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <div class="col-lg-4 col-md-6 col-sm-12">
                                                <label className='text-end optional'>وضوح إرشادات الكادر في السيارات :</label>
                                                <Form.Item
                                                    name='instructions'
                                                    className='my-0'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل الزامي',
                                                        },
                                                    ]}
                                                    hasFeedback
                                                >
                                                    <Select
                                                        size="large"
                                                        className='col-12'
                                                        placeholder='وضوح إرشادات الكادر في السيارات'
                                                    >
                                                        {status.map(item => <option value={item}>{item}</option>)}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <div class="col-lg-4 col-md-6 col-sm-12">
                                                <label className='text-end optional'>عمل المتطوعين :</label>
                                                <Form.Item
                                                    name='volunteers'
                                                    className='my-0'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل الزامي',
                                                        },
                                                    ]}
                                                    hasFeedback
                                                >
                                                    <Select
                                                        size="large"
                                                        className='col-12'
                                                        placeholder='عمل المتطوعين'
                                                    >
                                                        {status.map(item => <option value={item}>{item}</option>)}
                                                    </Select>
                                                </Form.Item>
                                            </div>

                                            <div class="col-lg-4 col-md-6 col-sm-12">
                                                <label className='text-end optional'>وضوح الرواة بالطرح والكلام :</label>
                                                <Form.Item
                                                    name='speech'
                                                    className='my-0'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل الزامي',
                                                        },
                                                    ]}
                                                    hasFeedback
                                                >
                                                    <Select
                                                        size="large"
                                                        className='col-12'
                                                        placeholder='وضوح الرواة بالطرح والكلام'
                                                    >
                                                        {status.map(item => <option value={item}>{item}</option>)}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <div class="col-lg-4 col-md-6 col-sm-12">
                                                <label className='text-end optional'>جودة وجبات الطعام :</label>
                                                <Form.Item
                                                    name='food'
                                                    className='my-0'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل الزامي',
                                                        },
                                                    ]}
                                                    hasFeedback
                                                >
                                                    <Select
                                                        size="large"
                                                        className='col-12'
                                                        placeholder='جودة وجبات الطعام'
                                                    >
                                                        {status.map(item => <option value={item}>{item}</option>)}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <div class="col-lg-4 col-md-6 col-sm-12">
                                                <label className='text-end optional'>راحة مكان المبيت :</label>
                                                <Form.Item
                                                    name='overnight'
                                                    className='my-0'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل الزامي',
                                                        },
                                                    ]}
                                                    hasFeedback
                                                >
                                                    <Select
                                                        size="large"
                                                        className='col-12'
                                                        placeholder='راحة مكان المبيت'
                                                    >
                                                        {status.map(item => <option value={item}>{item}</option>)}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <div class="col-lg-4 col-md-6 col-sm-12">
                                                <label className='text-end optional'>جودة الإضاءة :</label>
                                                <Form.Item
                                                    name='lighting'
                                                    className='my-0'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل الزامي',
                                                        },
                                                    ]}
                                                    hasFeedback
                                                >
                                                    <Select
                                                        size="large"
                                                        className='col-12'
                                                        placeholder='جودة الإضاءة'
                                                    >
                                                        {status.map(item => <option value={item}>{item}</option>)}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <div class="col-lg-4 col-md-6 col-sm-12">
                                                <label className='text-end optional'>جودة الصوتيات :</label>
                                                <Form.Item
                                                    name='acoustics'
                                                    className='my-0'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل الزامي',
                                                        },
                                                    ]}
                                                    hasFeedback
                                                >
                                                    <Select
                                                        size="large"
                                                        className='col-12'
                                                        placeholder='جودة الصوتيات'
                                                    >
                                                        {status.map(item => <option value={item}>{item}</option>)}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <div class="col-lg-4 col-md-6 col-sm-12">
                                                <label className='text-end optional'>جودة تعامل الكادر :</label>
                                                <Form.Item
                                                    name='team'
                                                    className='my-0'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل الزامي',
                                                        },
                                                    ]}
                                                    hasFeedback
                                                >
                                                    <Select
                                                        size="large"
                                                        className='col-12'
                                                        placeholder='جودة تعامل الكادر'
                                                    >
                                                        {status.map(item => <option value={item}>{item}</option>)}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <div class="col-lg-4 col-md-6 col-sm-12">
                                                <label className='text-end optional'>منصة معراج على الفيسبوك :</label>
                                                <Form.Item
                                                    name='facebook'
                                                    className='my-0'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل الزامي',
                                                        },
                                                    ]}
                                                    hasFeedback
                                                >
                                                    <Select
                                                        size="large"
                                                        className='col-12'
                                                        placeholder='منصة معراج على الفيسبوك'
                                                    >
                                                        {status.map(item => <option value={item}>{item}</option>)}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <div class="col-lg-4 col-md-6 col-sm-12">
                                                <label className='text-end optional'>منصة معراج على الانستكرام :</label>
                                                <Form.Item
                                                    name='instagram'
                                                    className='my-0'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل الزامي',
                                                        },
                                                    ]}
                                                    hasFeedback
                                                >
                                                    <Select
                                                        size="large"
                                                        className='col-12'
                                                        placeholder='منصة معراج على الانستكرام'
                                                    >
                                                        {status.map(item => <option value={item}>{item}</option>)}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <div class="col-lg-4 col-md-6 col-sm-12">
                                                <label className='text-end optional'>منصة معراج على التلكرام :</label>
                                                <Form.Item
                                                    name='telegram'
                                                    className='my-0'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل الزامي',
                                                        },
                                                    ]}
                                                    hasFeedback
                                                >
                                                    <Select
                                                        size="large"
                                                        className='col-12'
                                                        placeholder='منصة معراج على التلكرام'
                                                    >
                                                        {status.map(item => <option value={item}>{item}</option>)}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <div class="col-12">
                                                <label className='text-end optional'>ملاحظات أو مقترحات تود إضافتها حول الرحلة  :</label>
                                                <Form.Item
                                                    name='notes'
                                                    className='my-0'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل الزامي',
                                                        },
                                                    ]}
                                                    hasFeedback
                                                >
                                                    <Input.TextArea
                                                        rows={5}
                                                        placeholder='ملاحظات أو مقترحات تود إضافتها حول الرحلة '
                                                    />

                                                </Form.Item>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {current === 2 &&
                                <div style={{ ...contentStyle }}>
                                    <div className='d-flex justify-content-start align-items-center w-100'>
                                        <p className='step-title'>تقييم المحطات</p>
                                    </div>
                                    <div class="container-fluid p-2 mt-1 mb-4 ">
                                        <div class="row justify-content-start align-items-start g-2">

                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                <label className='text-end optional'>المحطة الاولى ( المقام ) :</label>
                                                <Form.Item
                                                    name='first_station'
                                                    className='my-0'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل الزامي',
                                                        },
                                                    ]}
                                                    hasFeedback
                                                >
                                                    <Select
                                                        size="large"
                                                        className='col-12'
                                                        placeholder='المحطة الاولى ( المقام )'
                                                    >
                                                        {status.map(item => <option value={item}>{item}</option>)}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                <label className='text-end optional'>المحطة الثانية ( المعلم ) :</label>
                                                <Form.Item
                                                    name='second_station'
                                                    className='my-0'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل الزامي',
                                                        },
                                                    ]}
                                                    hasFeedback
                                                >
                                                    <Select
                                                        size="large"
                                                        className='col-12'
                                                        placeholder='المحطة الثانية ( المعلم )'
                                                    >
                                                        {status.map(item => <option value={item}>{item}</option>)}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                <label className='text-end optional'>المحطة الثالثة ( النخلة )  براوجلي ( الحجية ) :</label>
                                                <Form.Item
                                                    name='third_station'
                                                    className='my-0'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل الزامي',
                                                        },
                                                    ]}
                                                    hasFeedback
                                                >
                                                    <Select
                                                        size="large"
                                                        className='col-12'
                                                        placeholder='المحطة الثالثة ( النخلة )  براوجلي ( الحجية )'
                                                    >
                                                        {status.map(item => <option value={item}>{item}</option>)}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                <label className='text-end optional'>المحطة الرابعة ( التل ) :</label>
                                                <Form.Item
                                                    name='fourth_station'
                                                    className='my-0'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل الزامي',
                                                        },
                                                    ]}
                                                    hasFeedback
                                                >
                                                    <Select
                                                        size="large"
                                                        className='col-12'
                                                        placeholder='المحطة الرابعة ( التل )'
                                                    >
                                                        {status.map(item => <option value={item}>{item}</option>)}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                <label className='text-end optional'>المحطة الخامسة ( الساتر ) :</label>
                                                <Form.Item
                                                    name='fifth_station'
                                                    className='my-0'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل الزامي',
                                                        },
                                                    ]}
                                                    hasFeedback
                                                >
                                                    <Select
                                                        size="large"
                                                        className='col-12'
                                                        placeholder='المحطة الخامسة ( الساتر )'
                                                    >
                                                        {status.map(item => <option value={item}>{item}</option>)}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                <label className='text-end optional'>المحطة السادسة ( النصب ) ( الشمسية ) :</label>
                                                <Form.Item
                                                    name='seventh_station'
                                                    className='my-0'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل الزامي',
                                                        },
                                                    ]}
                                                    hasFeedback
                                                >
                                                    <Select
                                                        size="large"
                                                        className='col-12'
                                                        placeholder='المحطة السادسة ( النصب ) ( الشمسية )'
                                                    >
                                                        {status.map(item => <option value={item}>{item}</option>)}
                                                    </Select>
                                                </Form.Item>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            }

                            {current === 4 && <div style={{ ...contentStyle }}><Res /></div>}
                            <div
                                style={{
                                    marginTop: 24,
                                    direction: 'ltr',
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}
                            >
                                {current === 0 && (
                                    <Button
                                        type=""
                                        className='survey-btn'
                                        onClick={() => next()}
                                    >
                                        التالي
                                    </Button>
                                )}
                                {current === 2 && (
                                    <Button
                                        type=""
                                        className='survey-btn'
                                        htmlType='submit'
                                    >
                                        التالي
                                    </Button>
                                )}
                                {current === 3 && (
                                    <Button
                                        type=""
                                        className='survey-btn'
                                        htmlType='submit'
                                    >
                                        التالي
                                    </Button>
                                )}
                                {current === 1 && (
                                    <Button
                                        type=""
                                        className='survey-btn'
                                        htmlType='submit'
                                    >
                                        التالي
                                    </Button>
                                )}
                                {current === steps.length - 1 &&
                                    (
                                        <Button type="" className='survey-btn' onClick={() => popForm('showOne', true)}>
                                            رفع بيانات الأستمارة
                                        </Button>
                                    )
                                }
                                {current > 0 &&
                                    (
                                        <Button
                                            style={{
                                                margin: '0 8px',
                                            }}
                                            onClick={() => prev()}
                                        >
                                            السابق
                                        </Button>
                                    )
                                }
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
            <AddPop
                showOne={formPop.showOne}
                showTwo={formPop.showTwo}
                handleshow={(e) => {
                    popForm('showOne', false)
                    postData(e)
                    popForm('showTwo', true)
                }}
                handleCloseOne={() => popForm('showOne', false)}
                handleCloseTwo={() => popForm('showTwo', false)}
                post={post.progress}
            />
            <ErrorPop
                showError={formPop.showError}
                handleCloseError={() => popForm('showError', false)}
                errorMessage={formPop.errorMessage}
            />
            <OKPop
                showError={formPop.ok}
                handleCloseError={() => popForm('ok', false)}
            />
        </>
    )
}
