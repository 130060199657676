import React, { useEffect, useState } from 'react';
import { Button, Form, Input, InputNumber, message, Result, Select, Steps, theme } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
import { AddPop, ErrorPop, OKPop } from './PopForm';
export default function SurveyBagay() {
    const [form] = Form.useForm()
    const { token } = theme.useToken();
    const [current, setCurrent] = useState(0);
    const [trips, setTrips] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        trips: '',
        sex: '',
        type: 1,
        season: 5,
        age: 0,
        city: '',
        education: '',
        phone: '',
        survey_link: '',
        has_children: false,
        children_count: 0,
        children_ages: [],
        previous_trips: false,
    });
    const [post, setPost] = useState({
        progress: 0,
        count: 0,
    })
    // search values to select forms
    const [formPop, setFormPop] = useState({
        showOne: false,
        showTwo: false,
        showError: false,
        ok: false,
        errorMessage: '',
    })
    // func to change values in form
    const change = (name, value) => {
        setFormData((state) => {
            return { ...state, [name]: value }
        })
    }
    const changePost = (name, value) => {
        setPost((state) => {
            return { ...state, [name]: value }
        })
    }
    // func to change values in pop form
    const popForm = (name, value) => {
        setFormPop((state) => {
            return { ...state, [name]: value }
        })
    }
    const handleFieldValueChange = (e, index) => {
        const newFieldValues = [...formData.children_ages];
        newFieldValues[index] = Number(e.target.value);
        setFormData((state) => {
            return { ...state, children_ages: newFieldValues }
        })
    };

    const postData = async (event) => {
        event.preventDefault()
        const UploadProgress = (event) => {
            const { loaded, total } = event;
            let percent = Math.floor((loaded * 100) / total);
            console.log(`${loaded}kb of ${total}kb | ${percent}%`);
            changePost('progress', percent)
        }
        const option = {
            timeout: 5000000,
            onUploadProgress: UploadProgress
        }

        await axios.post('/m3raaj/api/v1/survey/', formData, option)
            .catch(error => {
                popForm('showTwo', false);
                popForm('showError', true);
                changePost('progress', 0);
                popForm('errorMessage', "رجاءا تاكد من الاتصال");
            }).then(res => {
                console.log(res.status)
                if (res.status === 201) {
                    popForm('showTwo', false);
                    popForm('ok', true);
                    setTimeout(() => {
                        window.location.reload()
                    }, 2000);
                }
            })
    }
    const next = () => {

        setCurrent(current + 1);
    };
    const prev = () => {
        setCurrent(current - 1);
    };
    const city = ["أربيل", "الانبار", "بابل", "بغداد", "البصرة", "دهوك", "القادسية", "ديالى", "ذي قار", "السليمانية", "صلاح الدين", "كركوك", "كربلاء", "المثنى", "النجف", "نينوى", "واسط", "ميسان"]
    const part = ["16/02/2024 - الرحلة الاولى", "01/03/2024 - الرحلة الثانية"/*, "03/11/2023 - الرحلة الثالثة", "10/11/2023 - الرحلة الرابعة", "17/11/2023 - الرحلة الخامسة", "24/11/2023 - الرحلة السادسة", "01/12/2023 - الرحلة السابعة", "08/12/2023 - الرحلة الثامنة"*/]
    const edu = ["دكتوراه", "ماجستير", "بكالوريوس", "دبلوم", "اعدادية", "متوسطة", "ابتدائية", "يقرأ ويكتب"]
    const link = ["الأصدقاء", "فيسبوك", "انستكرام", "تلجرام"]
    const Informations = () => {
        return (
            <div className='d-flex flex-column justify-content-start align-items-start'>
                <div className='information d-flex flex-column justify-content-start align-items-start'>
                    <div className='d-flex justify-content-start align-items-center w-100'>
                        <p className='step-title'>التوجيهات العامة</p>
                    </div>
                    <p className='information-text'><p>1</p> مكان التجمع في قصر الأعظمية (مركز الرسول الأعظم) .</p>
                    <p className='information-text'><p>2</p> وقت التجمع (7:00 ص) .</p>
                    <p className='information-text'><p>3</p> وقت الانطلاق (8:00 ص) .</p>
                    <p className='information-text'><p>4</p>  وقت العودة بعد يوم من تاريخ الانطلاق (8:00 م) . </p>
                </div>
                <div className='information d-flex flex-column justify-content-start align-items-start'>
                    <div className='d-flex justify-content-start align-items-center w-100'>
                        <p className='step-title'>الملاحظات العامة</p>
                    </div>
                    <p className='information-text'><p>1</p>   الاستمارة صالحة لشخص واحد(أي من يرغب بالحضور يجب عليه ملئ استمارة).</p>
                    <p className='information-text'><p>2</p>   الالتزام بتوجيهات المرشدين.</p>
                    <p className='information-text'><p>3</p> ممنوع أصطحاب السلاح والأركيلة</p>
                    <p className='information-text'><p>4</p>   الحضور قبل وقت الانطلاق بنصف ساعة</p>
                    <p className='information-text'><p>5</p> يسمح بإصطحاب الكاميرا لكن بعض المحطات يمنع فيها التصوير. </p>
                </div>
                <div className='d-flex justify-content-start align-items-center w-100'>
                    <p className='step-title'>معلومات الأتصال</p>
                </div>
                <div className='information d-flex flex-column justify-content-start align-items-start'>
                    <p className='information-text'><p>1</p> للاستعلام الاتصال على الرقم التالي  07730017295 </p>
                    <p className='information-text'><p>2</p> علماً أن الرقم يحتوي على واتساب و تليكرام.</p>
                </div>
            </div>
        )
    }

    const Res = () => {
        return (
            <Result
                status="success"
                title="تم ملء الاستمارة بنجاح"
            />
        )
    }
    const steps = [
        {
            title: 'التوجيهات العامة للمشاركين',
        },
        {
            title: 'بيانات الأستمارة',
        },
        {
            title: 'رفع البيانات',
        },
    ];
    useEffect(() => {
        async function getData() {

            await axios.get(`/setting/api/v1/settings/find/?key=trips`)
                .then(res => {
                    const data = res.data.value
                    if (data) {
                        const dataParse = JSON.parse(data)
                        // const newFieldValues = [...trip];
                        setTrips(dataParse)
                    }
                })
        }
        getData()
    }, [])
    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
    }));
    const contentStyle = {
        color: token.colorTextTertiary,
        backgroundColor: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        border: `1px dashed ${token.colorBorder}`,
        marginTop: 16,
    };
    const onPost = (values) => {
        if (values.has_children) {
            setFormData((state) => {
                return { ...state, ...values }
            })
        } else {
            setFormData((state) => {
                return { ...state, ...values, children_count: 0, children_ages: [] }
            })
        }
        next()
    }
    return (
        <>

            {/* <section className="hero d-flex align-items-center" style={{ minHeight: "100vh", overflow: 'auto' }}> */}
            <div className="container mb-5" style={{ minHeight: "80vh", overflow: 'auto', marginTop: 100 }} >
                <div className="row">
                    <div className="col-12 d-flex flex-column justify-content-center align-items-center">
                        <img src={require('../style/img/home/logo.png')} alt="" className="img-fluid" width={175} />
                        <h3 className='text-center pt-2 pb-5'>أستمارة رحلة بيجي</h3>
                    </div>
                    <div class="col-12 h-100">
                        <Form form={form} onFinish={(values) => onPost(values)}>
                            <Steps current={current} items={items} style={{ zIndex: 3 }} />

                            {current === 0 &&
                                <div style={{ ...contentStyle }}>
                                    <Informations />
                                </div>}
                            {current === 1 &&
                                <div style={{ ...contentStyle }}>
                                    <div className='d-flex justify-content-start align-items-center w-100'>
                                        <p className='step-title'>بيانات الأستمارة</p>
                                    </div>
                                    <div class="container-fluid p-2 mt-1 mb-4 ">
                                        <div class="row justify-content-start align-items-start g-2">

                                            <div class="col-12">
                                                <label className='text-end optional'>اختر الرحلة التي تناسبك :</label>
                                                <Form.Item
                                                    name='trips'
                                                    className='my-0'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل الزامي',
                                                        },
                                                    ]}
                                                    hasFeedback
                                                >
                                                    <Select
                                                        size="large"
                                                        className='col-12'
                                                        // defaultValue={formData.trips}
                                                        placeholder='اختر الرحلة التي تناسبك'
                                                    // onChange={(e) => change('trips', e)}
                                                    >
                                                        {/* <option value=''>اختر</option> */}
                                                        {part.map((item, index) => {
                                                            // if (!trips[index][index]) {
                                                            //     return null
                                                            // }
                                                            return (
                                                                <option value={item}>{item}</option>
                                                            )
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <div class="col-lg-3 col-md-6 col-sm-12">
                                                <label className='text-end optional'>الأسم الثلاثي :</label>
                                                <Form.Item
                                                    name='name'
                                                    className='my-0'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل الزامي',
                                                        },
                                                        {
                                                            message: 'الأسم لا يقل عن عشرة حروف',
                                                            min: 10,
                                                            max: 30
                                                        },
                                                        {
                                                            pattern: "^[\\u0600-\\u06FF\\s]*$",
                                                            message: 'الاسم يجب أن يكون بالعربية',
                                                        },
                                                        // {
                                                        //     validator: validateName,
                                                        // },
                                                    ]}
                                                    extra='الأسم الثلاثي يكون باللغة العربيه حصراً'
                                                    hasFeedback

                                                >
                                                    <Input type='text' defaultValue={formData.name} minLength={2} size="large" placeholder='الأسم الثلاثي' name='name' onChange={(e) => change('name', e.target.value)} />
                                                </Form.Item>
                                            </div>
                                            <div class="col-lg-3 col-md-6 col-sm-12">
                                                <label className='text-end optional'>المحافظة :</label>
                                                <Form.Item
                                                    name='city'
                                                    className='my-0'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل الزامي',
                                                        },
                                                    ]}
                                                    hasFeedback
                                                >
                                                    <Select
                                                        size="large"
                                                        className='col-12'
                                                        placeholder='المحافظة'
                                                    // onChange={(e) => change('city', e)}
                                                    // defaultValue={formData.city}


                                                    >
                                                        {city.map(item => <option value={item}>{item}</option>)}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <div class="col-lg-3 col-md-12 col-sm-12">
                                                <label className='text-end optional'>الجنس :</label>
                                                <Form.Item
                                                    name='sex'
                                                    className='my-0'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل الزامي',
                                                        },
                                                    ]}
                                                    hasFeedback
                                                >
                                                    <Select
                                                        size="large"
                                                        className='col-12'
                                                        placeholder='الجنس'
                                                    // defaultValue={formData.sex}
                                                    // onChange={(e) => change('sex', e)}
                                                    >
                                                        <option value='ذكر'>ذكر</option>
                                                        <option value='انثى'>انثى</option>

                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <div class="col-lg-3 col-md-6 col-sm-12">
                                                <label className='text-end optional'>العمر :</label>
                                                <Form.Item
                                                    name='age'
                                                    className='my-0'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل الزامي',
                                                        },
                                                    ]}
                                                    hasFeedback
                                                >
                                                    <InputNumber className='col-12' type='number' min={15} max={100} size="large" placeholder='العمر' name='age' />
                                                </Form.Item>
                                            </div>
                                            <div class="col-lg-4 col-md-6 col-sm-12">
                                                <label className='text-end optional'>التحصيل الدراسي :</label>
                                                <Form.Item
                                                    name='education'
                                                    className='my-0'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل الزامي',
                                                        },
                                                    ]}
                                                    hasFeedback
                                                >
                                                    <Select
                                                        size="large"
                                                        className='col-12'
                                                        placeholder='التحصيل الدراسي'
                                                    // onChange={(e) => change('education', e)}
                                                    // defaultValue={formData.education}
                                                    >
                                                        {edu.map(item => <option value={item}>{item}</option>)}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <div class="col-lg-4 col-md-6 col-sm-12">

                                                <label className='text-end optional'>رقم الهاتف :</label>
                                                <Form.Item
                                                    name='phone'
                                                    className='my-0'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل الزامي',
                                                        },
                                                        {
                                                            message: 'رقم الهاتف الحالي غير فعال',
                                                            min: 11,
                                                            max: 11
                                                        },
                                                        {
                                                            pattern: /^[0-9]+$/,
                                                            message: 'يجب أن يحتوي رقم الهاتف على أرقام فقط وباللغة الإنجليزية',
                                                        },
                                                    ]}
                                                    extra='رقم الهاتف لا يقل ولا يزيد عن 11 رقم وباللغة الإنجليزية'
                                                    hasFeedback
                                                >
                                                    <Input type='text' className='w-100' inputMode='numeric' size="large" maxLength={11} placeholder='رقم الهاتف' name='phone' />
                                                </Form.Item>
                                            </div>
                                            <div class="col-lg-4 col-md-6 col-sm-12">
                                                <label className='text-end optional'>من اين حصلت على رابط الاستمارة :</label>
                                                <Form.Item
                                                    name='survey_link'
                                                    className='my-0'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل الزامي',
                                                        },
                                                    ]}
                                                    hasFeedback
                                                >
                                                    <Select
                                                        size="large"
                                                        className='col-12'
                                                        placeholder='من اين حصلت على رابط الاستمارة'
                                                    // onChange={(e) => change('survey_link', e)}
                                                    // defaultValue={formData.survey_link}

                                                    >
                                                        {/* <option value=''>اختر</option> */}
                                                        {link.map(item => <option value={item}>{item}</option>)}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                <label className='text-end optional'>هل شاركت في الرحلات السابقة : </label>
                                                <Form.Item
                                                    name='previous_trips'
                                                    className='my-0'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل الزامي',
                                                        },
                                                    ]}
                                                    hasFeedback
                                                >
                                                    <Select
                                                        size="large"
                                                        className='col-12'
                                                        placeholder='هل شاركت في الرحلات السابقة '
                                                    // onChange={(e) => change('previous_trips', e)}
                                                    // defaultValue={formData.previous_trips}
                                                    >
                                                        <option value={true}>نعم</option>
                                                        <option value={false}>كلا</option>

                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12">
                                                <label className='text-end optional'>هل تصطحب معك أطفال :</label>
                                                <Form.Item
                                                    name='has_children'
                                                    className='my-0'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل الزامي',
                                                        },
                                                    ]}
                                                    hasFeedback
                                                >
                                                    <Select
                                                        size="large"
                                                        className='col-12'
                                                        placeholder='هل تصطحب معك أطفال'
                                                        onChange={(e) => change('has_children', e)}
                                                    // defaultValue={formData.has_children}
                                                    >
                                                        <option value={true}>نعم</option>
                                                        <option value={false}>كلا</option>
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            {formData.has_children &&
                                                <>
                                                    <div class="col-12">
                                                        <label className='text-end optional'> عدد الأطفال :</label>
                                                        <Form.Item
                                                            name='children_count'
                                                            className='my-0'
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message: 'هذا الحقل الزامي',
                                                                },
                                                            ]}
                                                            hasFeedback
                                                        >
                                                            <InputNumber className='col-12' type='number' min={0} max={10} size="large" placeholder='عدد الأطفال' name='children_count' onChange={(e) => change('children_count', Number(e))} />
                                                        </Form.Item>
                                                    </div>
                                                    {[...Array(formData.children_count).keys()].map((i, index) => {

                                                        return (
                                                            <div key={index + 1} class="col-lg-4 col-md-6 col-sm-12">
                                                                <label className='text-end optional'> عمر الطفل {index + 1} :</label>
                                                                <Form.Item
                                                                    name={'age' + index}
                                                                    className='my-0'
                                                                    rules={[
                                                                        {
                                                                            required: true,
                                                                            message: 'هذا الحقل الزامي',
                                                                        },
                                                                    ]}
                                                                    hasFeedback
                                                                >
                                                                    <Input className='col-12' onChange={(e) => handleFieldValueChange(e, index)} type='number' min={0} max={14} size="large" placeholder={`عمر الطفل ${index + 1}`} />
                                                                </Form.Item>
                                                            </div>
                                                        )
                                                    })
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                            {current === 2 && <div style={{ ...contentStyle }}><Res /></div>}
                            <div
                                style={{
                                    marginTop: 24,
                                    direction: 'ltr',
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}
                            >
                                {current === 0 && (
                                    <Button
                                        type=""
                                        className='survey-btn'
                                        onClick={() => next()}
                                    >
                                        التالي
                                    </Button>
                                )}
                                {current === 1 && (
                                    <Button
                                        type=""
                                        className='survey-btn'
                                        // onClick={() => next()}
                                        htmlType='submit'
                                    >
                                        التالي
                                    </Button>
                                )}
                                {current === steps.length - 1 &&
                                    (
                                        <Button type="" className='survey-btn' onClick={() => popForm('showOne', true)}>
                                            رفع بيانات الأستمارة
                                        </Button>
                                    )
                                }
                                {current > 0 &&
                                    (
                                        <Button
                                            style={{
                                                margin: '0 8px',
                                            }}
                                            onClick={() => prev()}
                                        >
                                            السابق
                                        </Button>
                                    )
                                }
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
            <AddPop
                showOne={formPop.showOne}
                showTwo={formPop.showTwo}
                handleshow={(e) => {
                    popForm('showOne', false)
                    postData(e)
                    popForm('showTwo', true)
                }}
                handleCloseOne={() => popForm('showOne', false)}
                handleCloseTwo={() => popForm('showTwo', false)}
                post={post.progress}
            />
            <ErrorPop
                showError={formPop.showError}
                handleCloseError={() => popForm('showError', false)}
                errorMessage={formPop.errorMessage}
            />
            <OKPop
                showError={formPop.ok}
                handleCloseError={() => popForm('ok', false)}
            />
            {/* </section> */}
        </>
    )
}
